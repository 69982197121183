'use client'
import { useEffect, useState } from 'react'
import axios from 'axios'
import LoadingState from './main/element/LoadingState'
import { useLocale } from 'next-intl'

/*
กรอก Redirect Mapping ใน sheet นี้:
https://docs.google.com/spreadsheets/d/1yes_POuwD-L4jboY8zrpqcv_ofYbOtQ8Lq4xaBJprpA
*/

const SeoRedirect = ({ redirect = false }) => {
  //create isRedirecting state
  const [isRedirecting, setIsRedirecting] = useState(false)
  const locale = useLocale()

  const redirectToHome = () => {
    if (
      redirect &&
      window.location.pathname !== '/' &&
      window.location.pathname !== `/${locale}`
    ) {
      window.location.href = `/${locale}`
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/next-api/redirect', {
          // timeout
          timeout: 5000,
        })
        let redirectList = response.data?.data

        // reverse the list so the last item will be the first to check
        redirectList.reverse()

        //Clean up current url before comparing
        const currentUrl = window?.location?.href
          //we need no host url when comparing
          ?.replace(window?.location?.host, '')
          //we need no http or https when comparing
          ?.replace(/^https?:\/\//i, '')
          //if the last character is '/' remove it
          ?.replace(/\/$/, '')

        //Direct Case
        const directRedirect = redirectList
          ?.filter((x) => x.method === 'direct')
          ?.find((item) => currentUrl === item.from)

        //Regex Case
        const regexRedirect = redirectList
          .filter((x) => x.method === 'regex')
          ?.find((item) => {
            const regex = new RegExp(item.from)
            return regex.test(currentUrl)
          })
        if (directRedirect) {
          setIsRedirecting(true)
          // replace the part of currentUrl to directRedirect.to
          window.location.href = currentUrl.replace(
            directRedirect.from,
            directRedirect.to
          )
        } else if (regexRedirect) {
          setIsRedirecting(true)
          // Regex pattern to match the source URL and capture the number
          var regex = new RegExp(regexRedirect.from)
          // Perform the redirect using the replace() method
          var redirectUrl = currentUrl
            .replace(regex, regexRedirect.to)
            .replace(/\/(\w{2})\/(\w{2})\//, '/$2/') // Replace if two locales are found
          window.location.href =
            window.location.protocol + '//' + window.location.host + redirectUrl
        } else {
          setIsRedirecting(false)
        }
      } catch (error) {
        console.error('Error fetching redirect list:', error)
      } finally {
        redirectToHome()
      }
    }
    fetchData()
    return () => {
      setIsRedirecting(false)
    }
  }, [isRedirecting, locale, redirect])
  // loading
  if (isRedirecting) {
    return (
      <div className="tw-top-0 tw-left-0 tw-flex tw-w-full tw-h-full tw-z-50  tw-fixed tw-items-center tw-justify-center backdrop-filter -white ">
        <LoadingState />
      </div>
    )
  }
  return null
}

export default SeoRedirect
